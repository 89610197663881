<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">Complaint</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <table class="table" v-if="user">
            <tbody>
                <tr v-for="(v, k, index) in user" :key="index" v-if="k !== 'id' && k !== 'all_groups' && k !== 'options' && k !== 'complaint_type_display' && k !== 'root_cause_display' && k !== 'status_display'  && k !== 'attachments'">
                    <th>{{ k|capitalize }}</th>
                    <td v-if="k === 'complaint_type'">
                        <b-form-select v-model="model.complaint_type" :options="options.complaint_types"  :state="validateState(k)"></b-form-select>
                    </td>
                    <td v-else-if="k === 'area'">
                        <b-form-select v-model="model.area" :options="options.area"  :state="validateState(k)"></b-form-select>
                    </td>
                    <td v-else-if="k === 'channel'">
                        <b-form-select v-model="model.channel" :options="options.channel"  :state="validateState(k)"></b-form-select>
                    </td>
                    <td v-else-if="k === 'root_cause'">
                        <b-form-select v-model="model.root_cause" :options="options.root_causes"  :state="validateState(k)"></b-form-select>
                    </td>
                    <td v-else-if="k === 'root_cause_category'">
                        <b-form-select v-model="model.root_cause_category" :options="options.complaint_types"  :state="validateState(k)"></b-form-select>
                    </td>
                    <td v-else-if="k === 'fos_eligible'">
                        <b-form-select v-model="model.fos_eligible" :options="options.fos_eligible"  :state="validateState(k)"></b-form-select>
                    </td>
                    <td v-else-if="k === 'fca_complaint_category'">
                        <b-form-select v-model="model.fca_complaint_category" :options="options.fca_complaint_category"  :state="validateState(k)"></b-form-select>
                    </td>
                    <td v-else-if="k === 'fca_complaint_sub_category'" v-show="model.fca_complaint_category">
                        <b-form-select v-model="model.fca_complaint_sub_category" :options="fca_sub_cats" :state="validateState(k)"></b-form-select>
                    </td>
                    <td v-else-if="k === 'coronavirus_complaint'">
                        <b-form-select v-model="model.coronavirus_complaint" :options="yes_no"  :state="validateState(k)"></b-form-select>
                    </td>
                    <td v-else-if="k === 'fos_decision'">
                        <b-form-select v-model="model.fos_decision" :options="options.fos_decision"  :state="validateState(k)"></b-form-select>
                    </td>
                    <td v-else-if="k === 'status'">
                        <b-form-select v-model="model.status" :options="options.statuses"  :state="validateState(k)"></b-form-select>
                    </td>
                    <td v-else-if="k === 'summary'">
                        <b-form-textarea class="form-control" v-model="model.summary" rows="6" :state="validateState(k)"></b-form-textarea>
                    </td>
                  <td v-else>{{ v }}</td>
                </tr>
            </tbody>
        </table>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <b-button @click="Submit" variant="outline-success" class="float-right mr-2">Submit</b-button>          
        </template>
      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";
  import { validationMixin } from 'vuelidate'
  import { required } from "vuelidate/lib/validators";

  export default {
    name: 'User',
    components: {Id},
    props: {
      caption: {
        type: String,
        default: 'User Details'
      },
    },
    mixin: [validationMixin],
    data: () => {
      return {
        user: null,
        pdf: null,
        pdf_link: null,
        get_document: false,
        model: {
          complaint_type: null,
          root_cause: null,
          status: null,
          area: null,
          channel: null,
          summary: null,
          root_cause_category: null,
          fos_eligible: null,
          fca_complaint_category: null,
          fca_complaint_sub_category: null,
          fos_decision: null,
          coronavirus_complaint: null,
        },
        options: {},
        yes_no: [{value: true, text: 'Yes'}, {value: false, text: 'No'}]
      }
    },
    validations() {
      return {
        model: {
          complaint_type: {
              required
          },
          root_cause: {
              required
          },
          status: {
              required
          },
          area: {
              required
          },
          channel: {
              required
          },
          summary: {
              required
          },
          root_cause_category: {
              required
          },
          fos_eligible: {
              required
          },
          fca_complaint_category: {
              required
          },
          fos_decision: {
              required
          },
          coronavirus_complaint: {
              required
          },
          summary: {
              required
          },
          fca_complaint_sub_category: {
            required
          }
        }
      }
    },
    methods: {
      validateState(name) {

        if (this.$v.model[name]) {
          const {$dirty, $error} = this.$v.model[name];
          return $dirty ? !$error : null;
        }
      },
      goBack() {
        this.$router.go(-1)
      },
      getUser() {
        axios.get('/complaints/' + this.$route.params.id + '/').then(
          response => {
            this.user = response.data
            this.options = response.data.options
            this.model.complaint_type = response.data.complaint_type
            this.model.root_cause = response.data.root_cause
            this.model.status = response.data.status
            this.model.area = response.data.area
            this.model.channel = response.data.channel
            this.model.root_cause_category = response.data.root_cause_category
            this.model.practical_needs = response.data.practical_needs
            this.model.summary = response.data.summary
            this.model.fos_eligible = response.data.fos_eligible
            this.model.fca_complaint_category = response.data.fca_complaint_category
            this.model.fos_decision = response.data.fos_decision
            this.model.coronavirus_complaint = response.data.coronavirus_complaint
            this.model.fca_complaint_sub_category = response.data.fca_complaint_sub_category
          }
        ).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            console.log('premium error');
            console.log(error.response)
          })
        return this.user
      },
      Submit() {

        this.error = false;

        this.$v.$touch();

        if(this.$v.$anyError) {
          return
        } else {
          // Valid

          let url = '/complaints/' + this.$route.params.id + '/';

          var post_data = this.model

          axios.put(url, post_data).then(
            response => {
              console.log(response.data)
              this.error = false;
              this.$router.replace({ name: 'Complaint', params: { id: this.$route.params.id } })
            }
          ).catch(error => {
              console.log('post error');
              console.log(error.response);

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
          })

        }
      }
    },
    computed: {
      hasFullAccess() {

        let access = ['full_admin',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
      fca_sub_cats() {

        if (this.options.nested_categories) {

          if (this.model.fca_complaint_category) {
            return this.options.nested_categories[String(this.model.fca_complaint_category)]
          } 

          return

        }

        return []

      },
      userGroups() {
        let groups = ''

        if (this.user) {

          this.user.all_groups.filter(item => {

            let i = 0;

            this.user.groups.forEach(group => {

              if (item.value === group) {
                groups += item.text

                i += 1;

                if (this.user.groups.length > 1) {
                    groups += ', '
                }
              }
            })


          })
        }

        return groups

      },
    },
    mounted() {
      this.getUser()
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getUser()
        );

      }
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
